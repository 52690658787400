import React, { useEffect, useState } from 'react';

import '../components/catalog/Catalog.scss';

import { modalClose, modalOpen } from '../utils/modal';

import Layout from '../components/layout/Layout';
import Seo from '../components/seo/Seo';
import Cart from '../components/cart/Cart';
import CatalogToggles from '../components/catalog/CatalogToggles';
import CatalogFilters from '../components/catalog/CatalogFilters';
import Breadcrumbs from '../components/breadcrumbs/Breadcrumbs';
import Items from '../components/items/Items';
import Product from '../components/product/Product';

const FIXED_BITCOIN_PRICE = 45000;

const CatalogPage = () => {
  const [catalogLoading, setCatalogLoading] = useState(true);

  const [cartOpen, setCartOpen] = useState(false);
  const [cartCounter, setCartCounter] = useState(0);
  const [cartItems, setCartItems] = useState({});

  const [items, setItems] = useState([]);

  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filters, setFilters] = useState({});

  const [productLoading, setProductLoading] = useState(false);
  const [productOpen, setProductOpen] = useState(false);
  const [product, setProduct] = useState(null);

  const [calculator, setCalculator] = useState({
    btcPrice: 0,
    electricPrice: 0,
    usdPrice: 0,
  });

  const fetchJson = async (url, options) => {
    const response = await fetch(url, options);
    return response.json();
  };

  const fetchCatalogResources = async () => {
    const [filter, miner, cart, calculator] = await Promise.allSettled([
      fetchJson('https://api.chivoz.ru/miner/filter'),
      fetchJson('https://api.chivoz.ru/miner', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      }),
      fetchJson(`https://api.chivoz.ru/cart/show`, {
        method: 'GET',
        credentials: 'include',
      }),
      fetchJson('https://api.chivoz.ru/calculator/values'),
    ]);

    return { filter, miner, cart, calculator };
  };

  useEffect(() => {
    const fetchData = async () => {
      const { filter, miner, cart, calculator } = await fetchCatalogResources();

      setFilters(filter.value);
      setItems(miner.value);
      setCartItems(cart.value);
      setCartCounter(Object.keys(cart.value.products).length);
      console.log('calculator', calculator);
      setCalculator(calculator.value);

      setCatalogLoading(false);
    };

    fetchData();
  }, []);

  const openFilters = () => {
    modalOpen();
    setFiltersOpen(true);
  };

  const closeFilters = () => {
    modalClose();
    setFiltersOpen(false);
  };

  const submitFilters = async (values, { setSubmitting }) => {
    const filters = await fetchJson('https://api.chivoz.ru/miner', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    });

    setSubmitting(false);
    setItems(filters);
  };

  const onAdd = async ({ id }) => {
    setCartOpen(true);

    const data = await fetchJson(`https://api.chivoz.ru/cart/add/${id}/1`, {
      method: 'GET',
      credentials: 'include',
    });

    setCartItems(data);
    setCartCounter(Object.keys(data.products).length);
  };

  const onCartOpen = () => {
    setCartOpen(true);
    setProductOpen(false);
  };

  const onRemove = async (id) => {
    const data = await fetchJson(`https://api.chivoz.ru/cart/remove/${id}`, {
      method: 'GET',
      credentials: 'include',
    });
    setCartItems(data);
    setCartCounter(Object.keys(data.products).length);
  };

  const onQuantityChange = async (id, qty) => {
    const data = await fetchJson(
      `https://api.chivoz.ru/cart/set/${id}/${qty}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );

    setCartItems(data);
    setCartCounter(Object.keys(data.products).length);
  };

  const onCartClear = async () => {
    await fetchJson('https://api.chivoz.ru/cart/clear', {
      method: 'GET',
      credentials: 'include',
    });

    setCartItems({ totalPrice: 0, products: [] });
    setCartCounter(0);
  };

  const onProductOpen = async ({ id }) => {
    modalOpen();
    setProductLoading(true);
    setProductOpen(true);

    const data = await fetchJson(`https://api.chivoz.ru/miner/${id}`);
    setProduct(data);
    setProductLoading(false);
  };

  const onProductClose = () => {
    modalClose();
    setProductOpen(false);
  };

  const onProductAdd = () => {
    onProductClose();
    onCartOpen();
  };

  return (
    <Layout setCartOpen={onCartOpen} cartCounter={cartCounter} catalog>
      <Seo title="Каталог | Чиним Возим" />

      <Cart
        isOpen={cartOpen}
        setCartOpen={setCartOpen}
        cartItems={cartItems}
        cartCounter={cartCounter}
        onRemove={onRemove}
        onQuantityChange={onQuantityChange}
        onCartClear={onCartClear}
      />

      <Product
        isLoading={productLoading}
        isOpen={productOpen}
        onProductOpen={onProductOpen}
        onProductClose={onProductClose}
        product={product}
        onQuantityChange={onQuantityChange}
        onProductAdd={onProductAdd}
        cartItems={cartItems}
      />

      <main>
        <div className="catalog">
          {catalogLoading ? (
            <div className="catalog__loader">
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <div className="container">
              <CatalogToggles
                openFilters={openFilters}
                onCartOpen={onCartOpen}
                cartCounter={cartCounter}
              />

              <div className="catalog__grid">
                <CatalogFilters
                  filtersOpen={filtersOpen}
                  closeFilters={closeFilters}
                  submitFilters={submitFilters}
                  filters={filters}
                />

                <div className="catalog__content">
                  <Breadcrumbs />

                  {items.length ? (
                    <Items
                      items={items}
                      calculator={calculator}
                      setCartOpen={setCartOpen}
                      onAdd={onAdd}
                      onProductOpen={onProductOpen}
                    />
                  ) : (
                    <div className="h3">Ничего не найдено</div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </Layout>
  );
};

export default CatalogPage;
