import React, { useState } from 'react';

import './Cart.scss';

import cx from 'classnames';

import CartForm from './CartForm';
import CartItemList from './CartItemList';
import CartSuccess from './CartSuccess';
import CartError from './CartError';
import CartEmpty from './CartEmpty';

import CloseIcon from './assets/close.inline.svg';

const Cart = ({
  isLoading,
  isOpen,
  setCartOpen,
  cartItems,
  cartCounter,
  onRemove,
  onQuantityChange,
  onCartClear,
}) => {
  const [main, setMain] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const onSubmitForm = (values, setSubmitting) => {
    setSubmitting(false);

    fetch('https://api.chivoz.ru/form/send', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    })
      .then((res) => {
        console.log(res);
        if (!res.ok) {
          throw Error('Что-то пошло не так');
        }

        onCartClear();
        setMain(false);
        setSubmitting(false);
        setSuccess(true);
      })
      .catch(() => {
        setMain(false);
        setSubmitting(false);
        setError(true);
      });
  };

  const onClose = () => {
    setCartOpen(false);
    setMain(true);
    setError(false);
    setSuccess(false);
  };

  if (!cartItems.products) {
    return null;
  }

  return (
    <div className={cx('cart', isOpen && 'cart--show')}>
      <div className="cart__backdrop" onClick={onClose} role="presentation" />

      <div className="cart__wrapper">
        <div className="container">
          <div className="cart__inner">
            <div className="cart__content">
              <div className="cart__top">
                <div className="h5  cart__title">
                  {isLoading ? 'Загрузка...' : 'Корзина'}
                </div>
                <button className="cart__close" onClick={onClose}>
                  <CloseIcon />
                </button>
              </div>

              {main &&
                !isLoading &&
                Object.keys(cartItems.products).length > 0 && (
                  <>
                    <CartItemList
                      cartCounter={cartCounter}
                      cartItems={cartItems}
                      onRemove={onRemove}
                      onQuantityChange={onQuantityChange}
                    />

                    <div className="cart__bottom">
                      <CartForm
                        cartItems={cartItems}
                        cartCounter={cartCounter}
                        onSubmitForm={onSubmitForm}
                      />
                    </div>
                  </>
                )}

              {main &&
                !isLoading &&
                !Object.keys(cartItems.products).length && <CartEmpty />}

              {success && <CartSuccess />}
              {error && <CartError />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
