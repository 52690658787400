import React from 'react';
import { Field, Form, Formik } from 'formik';

import cx from 'classnames';

import FiltersArrowIcon from './assets/filters-arrow.inline.svg';

const HashrateCheckbox = ({ field, form, label, value, ...rest }) => {
  const { name, value: formikValue } = field;
  const { setFieldValue } = form;

  const handleChange = () => {
    const values = formikValue || [];
    const index = values.indexOf(value);
    if (index === -1) {
      values.push(value);
    } else {
      values.splice(index, 1);
    }
    setFieldValue(name, values);
  };

  return (
    <label className="field-checkbox__name">
      <input
        type="checkbox"
        className="field-checkbox__input"
        onChange={handleChange}
        checked={formikValue.indexOf(value) !== -1}
        {...rest}
      />
      <span className="field-checkbox__name-text">
        {value.min}
        {value.max !== null ? ` - ${value.max}` : '+'}
      </span>
    </label>
  );
};

const CatalogFilters = ({
  filtersOpen,
  closeFilters,
  submitFilters,
  filters,
}) => {
  if (Object.keys(filters).length === 0) {
    return null;
  }

  return (
    <aside
      className={cx('catalog__side', filtersOpen && 'catalog__side--show')}
    >
      <button className="catalog__side-top" onClick={closeFilters}>
        <FiltersArrowIcon />
        Фильтры
      </button>

      <Formik
        initialValues={{
          minPrice: filters.minPrice,
          maxPrice: filters.maxPrice,
          brands: ['Bitmain', 'MicroBT'],
          hashrate: [],
          isUsed: ['0', '1'],
        }}
        onSubmit={submitFilters}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="catalog__side-inner">
              <div className="field-text  field-text--group">
                <div className="field-text__name">Цена</div>
                <div className="field-text__input-wrap">
                  <div className="field-text__group" data-label="₽">
                    <Field
                      type="number"
                      min={filters.minPrice}
                      name="minPrice"
                      className="field-text__input"
                      placeholder="то 70 000"
                    />
                  </div>
                  <div className="field-text__group" data-label="₽">
                    <Field
                      type="number"
                      max={filters.maxPrice}
                      name="maxPrice"
                      className="field-text__input"
                      placeholder="до 900 000"
                    />
                  </div>
                </div>
              </div>

              <div role="group" className="field-checkbox">
                <div className="field-checkbox__title">Производитель</div>
                {filters.brands.map((brand) => (
                  <div key={brand} className="field-checkbox__input-wrap">
                    <label className="field-checkbox__name">
                      <Field
                        className="field-checkbox__input"
                        type="checkbox"
                        name="brands"
                        value={brand}
                      />
                      <span className="field-checkbox__name-text">{brand}</span>
                    </label>
                  </div>
                ))}
              </div>

              <div role="group" className="field-checkbox">
                <div className="field-checkbox__title">Хешрейт, Th/s</div>
                {filters.hashrate.map((rate) => (
                  <div
                    key={`${rate.min} - ${rate.max}`}
                    className="field-checkbox__input-wrap"
                  >
                    <Field
                      component={HashrateCheckbox}
                      name="hashrate"
                      value={rate}
                    />
                  </div>
                ))}
              </div>

              <div className="field-checkbox">
                <div className="field-checkbox__title">Состояние</div>
                <div className="field-checkbox__input-wrap">
                  <label htmlFor="filters-new" className="field-checkbox__name">
                    <Field
                      id="filters-new"
                      className="field-checkbox__input"
                      type="checkbox"
                      name="isUsed"
                      value="0"
                    />
                    <span className="field-checkbox__name-text">Новое</span>
                  </label>
                </div>
                <div className="field-checkbox__input-wrap">
                  <label
                    htmlFor="filters-used"
                    className="field-checkbox__name"
                  >
                    <Field
                      id="filters-used"
                      className="field-checkbox__input"
                      type="checkbox"
                      name="isUsed"
                      value="1"
                    />
                    <span className="field-checkbox__name-text">Б/у</span>
                  </label>
                </div>
              </div>

              <div className="catalog__side-actions">
                <button
                  type="submit"
                  className="btn btn--full  btn--medium"
                  onClick={closeFilters}
                  disabled={isSubmitting}
                >
                  Принять
                </button>

                <button
                  type="reset"
                  className="btn btn--full  btn--secondary  btn--medium"
                >
                  Сбросить
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </aside>
  );
};

export default CatalogFilters;
