import React from 'react';

import CartItem from './CartItem';

const CartItemList = ({ cartItems, onRemove, onQuantityChange }) => {
  return (
    <>
      <div className="cart__items">
        {Object.entries(cartItems.products).map(([key, value]) => (
          <CartItem
            key={key}
            productId={key}
            item={value}
            onRemove={onRemove}
            onQuantityChange={onQuantityChange}
          />
        ))}
      </div>
    </>
  );
};

export default CartItemList;
