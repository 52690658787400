import React from 'react';
import Social from '../social/Social';

const CartSuccess = () => {
  return (
    <>
      <div className="cart__message">
        <div className="cart__message-inner">
          <div className="cart__message-title h4">Корзина пустая</div>
        </div>
      </div>

      <div className="cart__bottom">
        <div className="h5">
          Вы также можете связаться с нами по телефону и в мессенджерах
        </div>
        <a href="tel:+79951598098" className="cart__tel h5">
          +7 (995) 159-80-98
        </a>
        <Social orange />
      </div>
    </>
  );
};

export default CartSuccess;
