import React from 'react';

import './CartItem.scss';

import { numberWithSpaces } from '../../utils/number-with-spaces';

import PlusIcon from './assets/plus.inline.svg';
import MinusIcon from './assets/minus.inline.svg';
import DeleteIcon from './assets/delete.inline.svg';

const CartItem = ({ item, productId, onRemove, onQuantityChange }) => {
  const { product } = item;
  const { image, brand, model, chipModels } = product.miner;

  const qtyChange = (type) => {
    let counter = item.qty;
    const newQty = type === 'plus' ? ++counter : --counter;

    if (newQty < 1) return;

    onQuantityChange(productId, newQty);
  };

  return (
    <div className="cart-item">
      <div className="cart-item__image">
        <img src={`https://api.chivoz.ru/${image}`} alt="" />
      </div>

      <div className="cart-item__content">
        <div className="cart-item__text">
          <div className="cart-item__title">
            {brand} {model}
          </div>
          <div className="cart-item__subtitle">{chipModels}</div>
        </div>
        <div className="cart-item__tags">
          <div className="cart-item__tag">
            {product.isUsed ? 'Б/у' : 'Новое'}
          </div>
          <div className="cart-item__tag">{product.hashrate} TH/S</div>
        </div>
        <div className="cart-item__bottom">
          <div className="cart-item__qty">
            <button
              className="cart-item__qty-action"
              onClick={() => qtyChange('minus')}
            >
              <MinusIcon />
            </button>

            <div className="cart-item__qty-value">{item.qty}</div>

            <button
              className="cart-item__qty-action"
              onClick={() => qtyChange('plus')}
            >
              <PlusIcon />
            </button>
          </div>

          <div className="cart-item__price h5">
            {numberWithSpaces(product.price)} ₽
          </div>
        </div>
      </div>

      <button className="cart-item__delete" onClick={() => onRemove(productId)}>
        <DeleteIcon />
      </button>
    </div>
  );
};

export default CartItem;
