export const numWord = (value, words) => {
  let temp = Math.abs(value) % 100;
  let num = value % 10;

  if (temp > 10 && temp < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num === 1) return words[0];

  return words[2];
};
