import React from 'react';
import { Field, Form, Formik } from 'formik';

import * as Yup from 'yup';
import cx from 'classnames';
import MaskedInput from 'react-text-mask';

import { numWord } from '../../utils/numbers';
import { numberWithSpaces } from '../../utils/number-with-spaces';

const phoneNumberMask = [
  '+',
  '7',
  ' ',
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];
const phoneRegExp = /(?:\+?)[78]+[0-9() -]{16,17}/;

const ValidationScheme = Yup.object({
  phone: Yup.string()
    .matches(phoneRegExp, 'Введён неверный номер телефона')
    .required('Обязательное поле'),
  email: Yup.string().email('Введён неверный email'),
});

const onPhoneFocus = (e) => {
  e.target.selectionStart = 4;
  e.target.selectionEnd = 4;
};

const CartForm = ({ onSubmitForm, cartItems, cartCounter }) => {
  const submitForm = (values, { setSubmitting }) => {
    const formValues = { ...values, name: '' };
    onSubmitForm(formValues, setSubmitting);
  };

  return (
    <Formik
      initialValues={{
        phone: '+7',
        email: '',
      }}
      validationSchema={ValidationScheme}
      onSubmit={submitForm}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form>
          <div className="h5">Как с вами связаться?</div>

          <div className="cart__fields">
            <div
              className={cx(
                'input-group',
                'input-group--white',
                errors.phone && touched.phone && 'input-group--error',
              )}
            >
              <label htmlFor="callback-phone" className="form-label">
                Мобильный телефон*
              </label>

              <Field name="phone">
                {({ field }) => (
                  <MaskedInput
                    {...field}
                    mask={phoneNumberMask}
                    id="callback-phone"
                    placeholder="+7 (000) 123-45-67"
                    type="text"
                    className={cx(
                      'form-control',
                      errors.phone && touched.phone
                        ? 'text-input error'
                        : 'text-input',
                    )}
                    guide={false}
                    onFocus={onPhoneFocus}
                  />
                )}
              </Field>

              {errors.phone && touched.phone ? (
                <div className="invalid-feedback">{errors.phone}</div>
              ) : null}
            </div>

            <div
              className={cx(
                'input-group',
                'input-group--white',
                errors.email && touched.email && 'input-group--error',
              )}
            >
              <label htmlFor="callback-email" className="form-label">
                Электронная почта
              </label>

              <Field
                name="email"
                type="email"
                className="form-control"
                placeholder="example@yandex.ru"
              />
              {errors.email && touched.email ? (
                <div className="invalid-feedback">{errors.email}</div>
              ) : null}
            </div>
          </div>

          <div className="cart__summary">
            <div className="cart__summary-qty">
              <div className="cart__summary-qty-title">Итого:</div>
              <div className="cart__summary-qty-number">
                {cartCounter}{' '}
                {numWord(cartCounter, ['товар', 'товара', 'товаров'])}
              </div>
            </div>
            <div className="cart__summary-price h5">
              {numberWithSpaces(cartItems.totalPrice)} ₽
            </div>
          </div>

          <button
            type="submit"
            className="btn btn--full"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Идет отправка...' : 'Отправить'}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default CartForm;
