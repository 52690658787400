import React, { useState } from 'react';
import { Link } from 'gatsby';

import './Items.scss';

import cx from 'classnames';

import { numberWithSpaces } from '../../utils/number-with-spaces';

import ArrowIcon from './assets/arrow.inline.svg';

const Item = ({ item, calculator, onAdd, onProductOpen, staticLink }) => {
  const { brand, model, chipModels, products } = item;

  const newItems = products.filter((product) => product.isUsed === false);
  const usedItems = products.filter((product) => product.isUsed === true);
  const initialGroup = newItems.length > 0 ? newItems : usedItems;
  const initialGroupName = newItems.length > 0 ? 'new' : 'used';

  const [selectedGroup, setSelectedGroup] = useState(initialGroup);
  const [selectedGroupName, setSelectedGroupName] = useState(initialGroupName);
  const [selected, setSelected] = useState(products[0]);
  const [expanded, setExpanded] = useState(false);

  const selectGroup = (name, group) => {
    setSelectedGroup(group);
    setSelectedGroupName(name);
    setSelected(group[0]);
  };

  const selectedSubProduct = (id) => {
    setSelected(products.find((product) => product.id === id));
  };

  const openProduct = (item, selectedGroup, selectedGroupName, selected) => {
    const isUsed = selectedGroupName !== 'new';
    onProductOpen(item, selectedGroup, isUsed, selected.hashrate);
  };

  const { btcPrice, usdPrice, electricPrice } = calculator;
  const { miningMonth, consumption } = selected;

  const profit = (
    btcPrice * usdPrice * miningMonth -
    consumption * electricPrice
  ).toFixed(2);

  return (
    <div className="item">
      <div className="item__main">
        <div
          className="item__image"
          onClick={() =>
            openProduct(item, selectedGroup, selectedGroupName, selected)
          }
        >
          <img src={`https://api.chivoz.ru${item.image}`} alt="" />
        </div>
        <div
          className="item__name"
          onClick={() =>
            openProduct(item, selectedGroup, selectedGroupName, selected)
          }
        >
          <div className="item__value h5">
            {brand} {model}
          </div>
          <div className="item__subvalue">{chipModels}</div>
        </div>
        <div className="item__tabs">
          <div className="item__tabs-row">
            {newItems.length > 0 && (
              <button
                className={cx(
                  'item__tab',
                  selectedGroupName === 'new' && 'item__tab--active',
                )}
                onClick={() => selectGroup('new', newItems)}
              >
                Новое
              </button>
            )}

            {usedItems.length > 0 && (
              <button
                className={cx(
                  'item__tab',
                  selectedGroupName === 'used' && 'item__tab--active',
                )}
                onClick={() => selectGroup('used', usedItems)}
              >
                Б/у
              </button>
            )}
          </div>
          <div className="item__tabs-row  item__tabs-row--absolute">
            <span className="item__tabs-spacer">&nbsp;</span>
            <div className="item__tabs-absolute">
              {selectedGroup.length > 3 && !expanded ? (
                <>
                  {selectedGroup.slice(0, 3).map((product) => {
                    return (
                      <button
                        key={product.id}
                        className={cx(
                          'item__tab',
                          selected.id === product.id && 'item__tab--active',
                        )}
                        onClick={() => selectedSubProduct(product.id)}
                      >
                        {product.hashrate} TH/S
                      </button>
                    );
                  })}

                  <button
                    className="item__tab  item__tab--arrow"
                    onClick={() => setExpanded(true)}
                  >
                    <ArrowIcon />
                  </button>
                </>
              ) : (
                selectedGroup.map((product) => {
                  return (
                    <button
                      key={product.id}
                      className={cx(
                        'item__tab',
                        selected.id === product.id && 'item__tab--active',
                      )}
                      onClick={() => selectedSubProduct(product.id)}
                    >
                      {product.hashrate} TH/S
                    </button>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="item__col  item__col--consumption"
        data-name="Потребление"
      >
        <div className="item__value">{selected.consumption} кВт/мес</div>
        <div className="item__subvalue">{selected.power} кВт/ч</div>
      </div>
      <div className="item__col  item__col--profit" data-name="Прибыль">
        <div className="item__value">{profit} ₽/мес</div>
        <div className="item__subvalue">{selected.miningMonth} BTC/мес</div>
      </div>
      <div className="item__col  item__col--payback" data-name="Окупаемость">
        <div className="item__value">{selected.paybackMonths} месяцев</div>
      </div>
      <div className="item__col  item__col--price" data-name="">
        <div className="item__price h5">
          {numberWithSpaces(selected.price)} ₽
        </div>
        <div className="item__action">
          {staticLink ? (
            <Link to="/catalog" className="btn  btn--full  btn--small">
              В корзину
            </Link>
          ) : (
            <button
              className="btn  btn--full  btn--small"
              onClick={() => onAdd(selected)}
            >
              В корзину
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const Items = ({ items, calculator, onAdd, onProductOpen, staticLink }) => {
  if (!items) {
    return null;
  }

  return (
    <div className="items">
      <div className="items__head">
        <div className="items__head-main">
          <div className="items__th">&nbsp;</div>
          <div className="items__th">наименование</div>
        </div>
        <div className="items__th">Потребление</div>
        <div className="items__th">Прибыль</div>
        <div className="items__th">Окупаемость</div>
        <div className="items__th">&nbsp;</div>
      </div>
      {items?.map((item) => (
        <Item
          key={item.id}
          item={item}
          calculator={calculator}
          staticLink={staticLink}
          onAdd={onAdd}
          onProductOpen={onProductOpen}
        />
      ))}
    </div>
  );
};

export default Items;
