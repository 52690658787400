import React from 'react';
import { Link } from 'gatsby';

import './Breadcrumbs.scss';

const Breadcrumbs = () => {
  return (
    <div className="breadcrumbs">
      <div className="breadcrumbs__item">
        <Link to="/" className="breadcrumbs__link">
          Главная
        </Link>
      </div>
      <div className="breadcrumbs__item  breadcrumbs__item--active">
        <Link to="/catalog" className="breadcrumbs__link">
          Магазин
        </Link>
      </div>
    </div>
  );
};

export default Breadcrumbs;
