import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';

import cx from 'classnames';
import debounce from 'lodash.debounce';

import './Calculator.scss';

import { numberWithSpaces } from '../../utils/number-with-spaces';

import InfoIcon from './assets/info.inline.svg';

const Calculator = ({
  id,
  selectedProduct,
  calculator,
  profit,
  payback,
  setCalculator,
  onCalculatorUpdate,
}) => {
  const { btcPrice, electricPrice, usdPrice } = calculator;

  const [currency, setCurrency] = useState('RUB');
  const [currentBitcoin, setCurrentBitcoin] = useState(0);
  const [bitcoin, setBitcoin] = useState(0);
  const [usd, setUsd] = useState(0);
  const [electricity, setElectricity] = useState(0);

  useEffect(() => {
    setCurrentBitcoin(btcPrice);
    setBitcoin(btcPrice);
    setUsd(usdPrice);
    setElectricity(electricPrice);
  }, []);

  const onChange = (field, setMethod, value) => {
    if (value.trim().length > 0) {
      setMethod(parseFloat(value));
      const state = { ...calculator, [field]: parseFloat(value) };
      // console.log('state', state);
      onCalculatorUpdate(selectedProduct, state);
      setCalculator(state);
    }
  };

  const debouncedOnChange = debounce(onChange, 400);

  const fields = [
    {
      label: 'Курс биткоина',
      name: 'btcPrice',
      value: btcPrice,
      method: setBitcoin,
      helper: 'USD',
    },
    {
      label: 'Курс доллара',
      name: 'usdPrice',
      value: usdPrice,
      method: setUsd,
      helper: 'RUB',
    },
    {
      label: 'Стоимость 1 кВт',
      name: 'electricPrice',
      value: electricPrice,
      method: setElectricity,
      helper: '₽',
    },
  ];

  const currencySymbol = currency === 'RUB' ? '₽' : '$';

  return (
    <div className="calculator">
      <div className="h5 calculator__title">Калькулятор окупаемости</div>
      <div className="calculator__text">
        Введите стоимость биткоина, курс доллара к рублю и цену покупки
        электричества для расчета.
      </div>

      <Formik
        enableReinitialize={true}
        initialValues={{
          btcPrice,
          usdPrice,
          electricPrice,
        }}
        onSubmit={(values) => console.log(values)}
      >
        {({ setFieldValue, errors, touched }) => (
          <Form>
            <div className="calculator__fields">
              {fields.map((field) => (
                <div
                  key={field.name}
                  className={cx(
                    'input-group',
                    'input-group--white',
                    errors[field.name] &&
                      touched[field.name] &&
                      'input-group--error',
                  )}
                >
                  <label
                    htmlFor={`calculator-${field.name}`}
                    className="form-label"
                  >
                    {field.label}
                    {field.name === 'btcPrice' && (
                      <span className="tooltip">
                        <span className="tooltip__content">
                          <span>
                            Многие аналитики говорят о том, что он будет выше{' '}
                            <b>45 000$</b>
                          </span>
                          <span>
                            Актуальный курс биткоина:
                            <br />
                            <b>{currentBitcoin}$</b>
                          </span>
                        </span>
                        <span className="tooltip__trigger">
                          <InfoIcon />
                        </span>
                      </span>
                    )}
                  </label>

                  <div className="form-control-group" data-label={field.helper}>
                    <Field
                      id={`calculator-${field.name}`}
                      name={field.name}
                      type="number"
                      className="form-control"
                      onChange={(e) => {
                        debouncedOnChange(
                          field.name,
                          field.method,
                          e.target.value,
                        );
                        setFieldValue(field.name, e.target.value);
                      }}
                    />
                  </div>

                  {errors[field.name] && touched[field.name] ? (
                    <div className="invalid-feedback">{errors[field.name]}</div>
                  ) : null}
                </div>
              ))}
            </div>

            <div className="calculator__items">
              <div className="calculator__item">
                <div className="calculator__item-top">
                  <div className="h5 calculator__item-title">Прибыль</div>
                  <div className="calculator__item-actions">
                    <button
                      type="button"
                      className={cx(
                        'calculator__item-action',
                        currency === 'RUB' && 'calculator__item-action--active',
                      )}
                      onClick={() => setCurrency('RUB')}
                    >
                      RUB (₽)
                    </button>
                    <button
                      type="button"
                      className={cx(
                        'calculator__item-action',
                        currency === 'USD' && 'calculator__item-action--active',
                      )}
                      onClick={() => setCurrency('USD')}
                    >
                      USD ($)
                    </button>
                  </div>
                </div>
                <div className="calculator__item-rows">
                  <div className="calculator__item-row">
                    <div className="calculator__item-name">В месяц</div>
                    <div className="calculator__item-value">
                      {numberWithSpaces(profit.month[currency])}{' '}
                      {currencySymbol}
                    </div>
                  </div>
                  <div className="calculator__item-row">
                    <div className="calculator__item-name">12 месяцев</div>
                    <div className="calculator__item-value">
                      {numberWithSpaces(profit.year[currency])} {currencySymbol}
                    </div>
                  </div>
                  <div className="calculator__item-row">
                    <div className="calculator__item-name">36 месяцев</div>
                    <div className="calculator__item-value">
                      {numberWithSpaces(profit['3years'][currency])}{' '}
                      {currencySymbol}
                    </div>
                  </div>
                </div>
              </div>

              <div className="calculator__item">
                <div className="calculator__item-top">
                  <div className="h5 calculator__item-title">Окупаемость</div>
                </div>
                <div className="calculator__item-rows">
                  <div className="calculator__item-row">
                    <div className="calculator__item-name">В месяц</div>
                    <div className="calculator__item-value">
                      {payback.month}%
                    </div>
                  </div>
                  <div className="calculator__item-row">
                    <div className="calculator__item-name">12 месяцев</div>
                    <div className="calculator__item-value">
                      {payback.year}%
                    </div>
                  </div>
                  <div className="calculator__item-row">
                    <div className="calculator__item-name">36 месяцев</div>
                    <div className="calculator__item-value">
                      {payback['3years']}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Calculator;
