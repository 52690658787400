import React, { useState, useEffect } from 'react';

import cx from 'classnames';

import './Product.scss';

import { numberWithSpaces } from '../../utils/number-with-spaces';

import Calculator from '../calculator/Calculator';

import CloseIcon from './assets/close.inline.svg';
import CheckIcon from './assets/check.inline.svg';
import MinusIcon from '../cart/assets/minus.inline.svg';
import PlusIcon from '../cart/assets/plus.inline.svg';

const Product = ({
  isOpen,
  isLoading,
  product,
  onProductClose,
  onQuantityChange,
  onProductAdd,
}) => {
  const [additionalOpen, setAdditionalOpen] = useState(true);
  const [hashes, setHashes] = useState([]);
  const [selectedRate, setSelectedRate] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [qty, setQty] = useState(1);

  const [calculator, setCalculator] = useState({
    btcPrice: 0,
    electricPrice: 0,
    usdPrice: 0,
  });

  useEffect(() => {
    if (!product) return;

    fetch('https://api.chivoz.ru/calculator/values')
      .then((response) => response.json())
      .then(({ btcPrice, electricPrice, usdPrice }) => {
        setCalculator({ btcPrice, electricPrice, usdPrice });
      });

    const hashes = new Set();
    product.products.forEach((product) => hashes.add(product.hashrate));
    setHashes(Array.from(hashes));

    const firstRate = product.products[0].hashrate;
    const firstGroup = product.products.filter((item) => {
      return item.hashrate === firstRate;
    });

    setSelectedRate(firstRate);
    setSelectedGroup(firstGroup);
    setSelectedProduct(firstGroup[0]);
  }, [product]);

  if (!product || !selectedProduct) {
    return null;
  }

  const { brand, model, description, algo, weight, size, image, products } =
    product;

  const updateGroup = (rate) => {
    return products.filter((product) => product.hashrate === rate);
  };

  const onCalculatorUpdate = (item, { btcPrice, usdPrice, electricPrice }) => {
    fetch(`https://api.chivoz.ru/calculator/${item.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        btcPrice,
        electricPrice,
        usdPrice,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectedProduct({
          ...item,
          profit: data.profit,
          payback: data.payback,
        });
      });
  };

  const selectRate = (rate) => {
    setSelectedRate(rate);
    setSelectedGroup(updateGroup(rate));
    setSelectedProduct(updateGroup(rate)[0]);
    onCalculatorUpdate(updateGroup(rate)[0], calculator);
    setQty(1);
  };

  const onUsedChange = (item) => {
    setSelectedProduct(item);
    onCalculatorUpdate(item, calculator);
    setQty(1);
  };

  const onAdd = (item) => {
    onQuantityChange(item.id, qty);
    onProductAdd();
    setQty(1);
  };

  const closeProdct = () => {
    setQty(1);
    onProductClose();
  };

  return (
    <div className={cx('product', isOpen && 'product--show')}>
      <div
        className="product__backdrop"
        onClick={closeProdct}
        role="presentation"
      />

      <div className="product__wrapper">
        <div className="container">
          <div className="product__inner">
            <div
              className={cx(
                'product__content',
                isLoading && 'product__content--loading',
              )}
            >
              <div className="product__scroll">
                {isLoading ? (
                  <div className="product__loader">
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                ) : (
                  <>
                    <button className="product__close" onClick={closeProdct}>
                      <CloseIcon />
                    </button>

                    <div className="product__main">
                      <div className="product__features">
                        <div className="product__image">
                          <img src={`https://api.chivoz.ru${image}`} alt="" />

                          <div className="product__image-text">
                            <div className="product__manufacturer">
                              Производитель: {brand}
                            </div>
                            <h3 className="product__title">{model}</h3>
                          </div>
                        </div>
                        <div className="product__feature">
                          <div className="product__feature-top">
                            <div className="product__feature-title">
                              Доставка
                            </div>
                            <div className="product__feature-icon">
                              <CheckIcon />
                            </div>
                          </div>
                          <div className="product__feature-text">
                            14 дней под заказ из Китая
                          </div>
                        </div>
                        <div className="product__feature">
                          <div className="product__feature-top">
                            <div className="product__feature-title">
                              Гарантия
                            </div>
                            <div className="product__feature-icon">
                              <CheckIcon />
                            </div>
                          </div>
                          <div className="product__feature-text">До 1 года</div>
                        </div>
                      </div>

                      <div className="product__text">
                        <div className="product__manufacturer">
                          Производитель: {brand}
                        </div>
                        <h3 className="product__title">{model}</h3>
                        {description && (
                          <div className="product__description">
                            {description}
                          </div>
                        )}
                      </div>

                      <div className="product__hash">
                        <div className="h5 product__subtitle">
                          Мощность хэширования:
                        </div>

                        <div className="product__tabs">
                          {hashes.map((rate) => (
                            <button
                              key={rate}
                              className={cx(
                                'product__tab',
                                selectedRate === rate && 'product__tab--active',
                              )}
                              onClick={() => selectRate(rate)}
                            >
                              {rate} TH/S
                            </button>
                          ))}
                        </div>
                      </div>

                      {selectedGroup && (
                        <div className="product__used">
                          <h5 className="product__subtitle">
                            Состояние товара:
                          </h5>

                          <div className="product__used-items">
                            {selectedGroup.map((item) => {
                              const { isUsed } = item;

                              return (
                                <label
                                  key={item.id}
                                  className="product__used-item"
                                >
                                  <input
                                    className="product__used-input"
                                    type="radio"
                                    name="isUsed"
                                    value={item.isUsed}
                                    checked={
                                      item.isUsed === selectedProduct.isUsed
                                    }
                                    onChange={() => onUsedChange(item)}
                                  />
                                  <span className="product__used-inner">
                                    <span className="product__used-text">
                                      <span className="product__used-title">
                                        {isUsed ? 'С фермы' : 'Новый'}
                                      </span>
                                      <span className="product__used-subtitle">
                                        {isUsed
                                          ? 'Товар используемый предыдущем владельцем. Гарантия от нас'
                                          : 'Товар от поставщика без использования. Гарантия от производителя и от нас'}
                                      </span>
                                    </span>
                                    <span className="product__used-value">
                                      <span className="h5 product__used-price">
                                        {numberWithSpaces(item.price)} ₽
                                      </span>
                                      <span className="product__used-hourly">
                                        {numberWithSpaces(item.thPrice)} ₽/TH
                                      </span>
                                    </span>
                                  </span>
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      )}

                      <div className="product__details">
                        <div className="h5 product__subtitle">
                          Характеристики:
                        </div>

                        <div className="product__details-items">
                          <div className="product__details-item">
                            <div className="product__details-title">
                              Мощность
                            </div>
                            <div className="product__details-subtitle">
                              <span className="h5 product__details-value">
                                {selectedProduct.power}
                              </span>
                              <span className="product__details-subvalue">
                                кВт
                              </span>
                            </div>
                          </div>

                          <div className="product__details-item">
                            <div className="product__details-title">
                              Потребление
                            </div>
                            <div className="product__details-subtitle">
                              <span className="h5 product__details-value">
                                {selectedProduct.consumption}
                              </span>
                              <span className="product__details-subvalue">
                                кВт/мес
                              </span>
                            </div>
                          </div>

                          <div className="product__details-item">
                            <div className="product__details-title">Добыча</div>
                            <div className="product__details-subtitle">
                              <span className="h5 product__details-value">
                                {selectedProduct.miningMonth}
                              </span>
                              <span className="product__details-subvalue">
                                BTC
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={cx(
                          'product__additional',
                          additionalOpen && 'product__additional--open',
                        )}
                      >
                        <button
                          className="product__additional-toggle"
                          onClick={() => setAdditionalOpen(!additionalOpen)}
                        >
                          Дополнительные характеристики
                        </button>
                        <div className="product__additional-content">
                          <div className="product__additional-item">
                            <div className="product__additional-name">
                              Производитель
                            </div>
                            <div className="product__additional-value">
                              {brand}
                            </div>
                          </div>
                          <div className="product__additional-item">
                            <div className="product__additional-name">
                              Модель
                            </div>
                            <div className="product__additional-value">
                              {model}
                            </div>
                          </div>
                          <div className="product__additional-item">
                            <div className="product__additional-name">
                              Аглоритм
                            </div>
                            <div className="product__additional-value">
                              {algo}
                            </div>
                          </div>
                          <div className="product__additional-item">
                            <div className="product__additional-name">Вес</div>
                            <div className="product__additional-value">
                              {weight}
                            </div>
                          </div>
                          <div className="product__additional-item">
                            <div className="product__additional-name">
                              Размер без упаковки
                            </div>
                            <div className="product__additional-value">
                              {size}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="product__calculator">
                        <Calculator
                          calculator={calculator}
                          id={selectedProduct.id}
                          selectedProduct={selectedProduct}
                          profit={selectedProduct.profit}
                          payback={selectedProduct.payback}
                          setCalculator={setCalculator}
                          onCalculatorUpdate={onCalculatorUpdate}
                        />
                      </div>
                    </div>

                    <div className="product__sticky">
                      <div className="product__sticky-top">
                        <div className="product__sticky-name">
                          {brand} {model}
                        </div>
                        <div className="product__sticky-price">
                          {numberWithSpaces(selectedProduct.price * qty)} ₽
                        </div>
                      </div>

                      <div className="product__sticky-actions">
                        <div className="product__sticky-qty">
                          <div className="qty qty--bigger">
                            <button
                              className="qty__action"
                              type="button"
                              onClick={() => setQty(qty > 1 ? qty - 1 : qty)}
                            >
                              <MinusIcon />
                            </button>

                            <input
                              className="qty__input"
                              value={qty}
                              min="1"
                              step="1"
                              readOnly
                            />

                            <button
                              className="qty__action"
                              type="button"
                              onClick={() => setQty(qty + 1)}
                            >
                              <PlusIcon />
                            </button>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn--full btn--medium"
                          onClick={() => onAdd(selectedProduct)}
                        >
                          <span className="desktop">Добавить</span> в корзину
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
