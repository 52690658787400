import React from 'react';

import FiltersIcon from './assets/filters.inline.svg';
import CartIcon from './assets/cart.inline.svg';

const CatalogToggles = ({ openFilters, onCartOpen, cartCounter }) => {
  return (
    <div className="catalog__toggles">
      <button
        className="btn btn--small btn--transparent catalog__toggle"
        onClick={openFilters}
      >
        <span className="btn__icon">
          <FiltersIcon />
        </span>
        Фильтры
      </button>
      <button
        className="btn btn--small btn--grey"
        onClick={() => onCartOpen(true)}
      >
        <span className="btn__icon">
          <CartIcon />
        </span>
        Корзина
        <span className="btn__counter">{cartCounter}</span>
      </button>
    </div>
  );
};

export default CatalogToggles;
